
import React from 'react';
import Global from '../layout/global';
import Form from '../components/Form';
import StructuredList from '../components/StructuredList';
import { graphql } from 'gatsby';
import './partners.scss';

/**
 * Partners page describes how to become a partner and benefits.
 */
export default ({ data }) => (
    <Global
        title="Be A Copperhead Partner"
        background="/img/background/homepage3.jpg"
        description={ data.markdownRemark.frontmatter.description }
        keywords={ data.markdownRemark.frontmatter.keywords }
        page="partners">
        <div className="partners-upper">
            <div className="partners-upper-info">
                <h1 className="glitch" data-text="Be a Partner">Be a<br></br>Partner</h1>
                <p>
                    Become our trusted partner and join the Copperhead Network.
                    Grow your business, drive your sales and build your own
                    customized product bundles.
                </p>
                <span className="partners-upper-info-title">Benefits of partnership</span>
                <ul className="g-list">
                    <li>Distribute Copperhead software and services</li>
                    <li>Receive expert support for CopperheadOS</li>
                    <li>Access to sales leads</li>
                    <li>Be recognized as an official Copperhead partner</li>
                </ul>
            </div>
            <Form
                id="getstarted"
                // captcha={ true }
                action="/api/partner"
                method="POST"
                title="Register to become a partner"
                notation="Partners are required to have an operational website and must
                    have a legally incorporated company in the region they
                    distribute or service in. Apply via website or call in to go
                    over the secondary requirements."
                sentMessage="Your message has been sent. A representive will be in touch with you shortly.">
                <fieldset>
                    <label htmlFor="name">Contact information</label>
                    <input type="text" name="name" aria-label="Your name" placeholder="Name *" required />
                    <input type="text" name="company" aria-label="Your company" placeholder="Company *" required />
                    <input type="tel" name="phone" aria-label="Your phone number" placeholder="Phone"/>
                    <input type="text" name="country" aria-label="Your country" placeholder="Country *" required />
                    <input type="email" name="email" aria-label="Your email address" placeholder="Email address *" required />
                </fieldset>
                <fieldset>
                    <label htmlFor="option1">Partner channel</label>
                    <ul className="g-option-list">
                        <li>
                            <input id="option1" type="radio" name="offerring" aria-label="Solution provider" value="Solutions Partner" />
                            <label htmlFor="option1">Solutions Partners</label>
                        </li>
                        <li>
                            <input id="option2" type="radio" name="offerring" aria-label="Technology partner" value="Technology Partner" />
                            <label htmlFor="option2">Technology Partners</label>
                        </li>
                        <li>
                            <input id="option3" type="radio" name="offerring" aria-label="OEM/ODM partner" value="OEM/ODM Partner" />
                            <label htmlFor="option3">ODM/OEM Partners</label>
                        </li>
                        <li>
                            <input id="option4" type="radio" name="offerring" aria-label="Regional coverage partner" value="Regional Coverage Partner" />
                            <label htmlFor="option4">Regional Coverage Partners (Resellers, Distributors)</label>
                        </li>
                    </ul>
                </fieldset>
            </Form>
        </div>
        <section className="partners-lower">
            <div className="partners-lower-content">
                <div className="partners-lower-left">
                    <h3>About partnership</h3>
                    <p>
                        Copperhead provides our partner network with the top level of support and engagement requirements.
                        Registered partners can deploy Copperhead solutions
                        and services to their respective clients.
                    </p>
                    <p>
                        Copperhead's partner program exists to assist organizations
                        in delivering value to their clients through hands-on
                        assistance for various developments.
                    </p>
                </div>
                <div className="partners-lower-right">
                    <img src="/img/icons/training-presentation.svg" alt="Training for membership" />
                    <h3>Training for membership</h3>
                    <ul>
                        <li>How to utilize the latest communication software on CopperheadOS</li>
                        <li>Service migration</li>
                        <li>Mobile security and data privacy best practices</li>
                        <li>Navigating Copperhead support</li>
                        <li>Android platform management</li>
                        <li>Operational threat model review</li>
                    </ul>
                </div>
            </div>
            <StructuredList title="Ways to partner">
                <li key="1" title="Solutions Partners">
                    For consultants and solutions providers looking to provide Copperhead hardware
                    and software
                </li>
                <li key="1" title="Technology Partners">
                    For technology product companies looking to bundle a solution with Copperhead
                </li>
                <li key="1" title="OEM Partners">
                    For device manufacturers and project managers interfacing for CopperheadOS on custom Android hardware
                </li>
                <li key="1" title="Regional Coverage Partners">
                    For value-added resellers, retailers, distributors, corporate resellers and mobile operators
                </li>
            </StructuredList>
        </section>
    </Global>
);

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: { path: { eq: "/partners" } }) {
            html
            frontmatter {
                title
                date
                description
                keywords
            }
        }
    }
`;
